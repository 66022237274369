<template lang="html">
  <div class="container" id="text-page" data-aos="fade">
    <h1 class="page-title txt-black">Aviso de Privacidad Integral CONFIOP Reingeniería Financiera</h1>

    <p class="mb-2">
      En cumplimiento con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de Particulares y con la finalidad de asegurar la protección y privacidad de los datos personales, así como regular el acceso, rectificación, oposición y cancelación de estos CONFIOP: CONTABILIDAD, FINANZAS, OPERACIONES en adelante CONFIOP establece lo siguiente:El presente documento constituye el Aviso de Privacidad Integral “API” de CONFIOP Reingeniería Financiera, mejor conocido como CONFIOP.
    </p>
    <p class="mb-2">
      En términos de lo previsto en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (la Ley), su reglamento, así como los lineamientos emitidos con relación al Aviso de Privacidad, CONFIOP Reingeniería Financiera., así como sus subsidiarias, afiliadas, controladora y demás empresas relacionadas, cada una en su carácter de responsable de sus datos personales, reconocen que la privacidad y la seguridad de su información personal y comercial es una garantía primordial para Usted, por lo que CONFIOP Reingeniería Financiera. está comprometida a resguardar dicha información con los más altos índices de seguridad legal, tecnológica y administrativa.
    </p>
    <p class="mb-2">
      Para efectos del presente “API”, CONFIOP Reingeniería Financiera. con domicilio en Av Elementia 125, int 5, Colonia Fraccionamiento Elemet, Código Postal 45645, como responsable del uso y protección de sus datos personales, al respecto le informa:
    </p>
    <p>
      Los datos personales de recabamos de Usted, los utilizaremos para las siguientes finalidades que son necesarias para la adecuada realización del servicio que solicita:
    </p>

    <br />
    <h5>Finalidades Primordiales</h5>
    <ul>
      <li>Proporcionarle asesoría en la contratación de nuestros servicios y productos. </li>
      <li>Dar seguimiento a su solicitud de servicios e identificación de riesgo en la contratación.</li>
      <li>La realización de todas y cada una de las operaciones y la prestación de los servicios que se otorgan.</li>
      <li>Celebrar los demás actos por parte de CONFIOP Reingeniería Financiera. con terceros, que sean necesarios para llevar a cabo el servicio solicitado.</li>
      <li>Atender requerimientos legales de autoridades competentes.</li>
      <li>Utilizarlos para cesión o descuento de cartera, así como para procesos de cobranza judicial o extrajudicial.</li>
    </ul>
    <p>
      De manera adicional, se podrá utilizar la información personal para las finalidades secundarias siguientes, mismas que no son necesarias para llevar a cabo el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:
    </p>

    <br />
    <h5>Finalidades Secundarias</h5>
    <ul>
      <li>Mejorar nuestro proceso comercial y de mercadotecnia, así como la realización de estudios de mercado.</li>
      <li>El diseño de servicios y/o productos y beneficios.</li>
      <li>Ponernos en contacto con usted para ofrecerle nuevos servicios y/o productos que pudieran ser de su interés, así como para conocer su opinión</li>
    </ul>
    <p class="mb-2">
      Si usted no acepta que sus datos se utilicen para las finalidades secundarias que se indican a continuación, deberá manifestar su negativa siguiendo el procedimiento siguiente:
    </p>
    <p>
      Dirigir solicitud a la dirección de correo electrónico: juridico@confiop.com, indicando en el cuerpo del correo la finalidad secundaria bajo la cual no desea que sus datos sean tratados.
    </p>
    <ul>
      <li>[ ] Mejorar nuestro proceso comercial y de mercadotecnia, así como la realización de estudios de mercado.</li>
      <li>[ ] El diseño de servicios y/o productos y beneficios.</li>
      <li>[ ] Ponernos en contacto con usted para ofrecerle nuevos servicios y/o productos que pudieran ser de su interés, así como para conocer su opinión</li>
    </ul>

    <p class="mb-2">
      *Esta negativa para el uso de sus datos personales no podrá ser motivo para negarle los productos o servicios que solicita o contrata con nosotros.
    </p>

    <p>
      ¿Qué datos personales utilizaremos para estos fines?<br />
      ○ Nombre completo<br />
      ○ Domicilio<br />
      ○ Correo electrónico<br />
      ○ Teléfono, celular o fax<br />
      ○ Registro Federal de Contribuyentes (RFC)<br />
      ○ Clave Única de Registro de Población (CURP)<br />
      ○ Fotografía<br />
      ○ Datos migratorios<br />
      ○ Lugar y fecha de nacimiento<br />
      ○ Número de cuenta bancaria e información patrimonial<br />
      ○ Ingresos<br />
      ○ Egresos<br />
      ○ Historial crediticio<br />
      ○ Imágenes y sonidos captados por los “SVV”
    </p>
    <br />
    <p>
      CONFIOP Reingeniería Financiera. no solicitará la obtención de sus Datos Personales Sensibles, entendiéndose por aquellos los cuales sean considerados íntimos o cuya utilización pueda dar origen a discriminación o conlleve un grave riesgo para Usted.
    </p>

    <br />
    <h5>Transferencia de Datos Personales</h5>
    <p class="mb-2">
      CONFIOP Reingeniería Financiera. podrá con fines de prospección comercial, operación, seguimiento y administración de crédito o de servicios complementarios al prestado, compartir sus datos personales dentro y fuera del país con sus empresas subsidiarias, afiliadas, controladora y demás empresas relacionadas, las cuales operarán con los mismos criterios, políticas y cuidado en el tratamiento de los mismos.
    </p>
    <p class="mb-2">
      De manera adicional, CONFIOP Reingeniería Financiera. podrá en caso de ser necesario en virtud de los servicios proporcionados, compartir sus datos personales con terceras personas nacionales o extranjeras, como es el caso de proveedores de bienes o servicios que requieran conocer esta información, por mencionar algunas: empresas aseguradoras, proveedores de servicios de correo electrónico o procesadores de datos automatizados.
    </p>
    <p class="mb-2">
      En estos casos el tercero receptor se obligará a guardar estricta confidencialidad de sus datos personales transmitidos y resguardarlos bajo los mismos criterios, políticas y cuidado en el tratamiento de los mismos.
    </p>
    <p class="mb-2">
      En caso de que, para realizar alguna transferencia de sus datos, se requiera de su consentimiento expreso, este se recabará.
    </p>

    <br />
    <h5>Medios y Procedimiento para ejercer los derechos ARCO</h5>
    <p>
      Usted tiene los siguientes derechos sobre sus datos personales (Derechos ARCO):
    </p>
    <ul>
      <li>Conocer qué datos personales tenemos de usted, para que los utilizamos y las condiciones del uso que les damos (Acceso).</li>
      <li>Solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación).</li>
      <li>A que eliminemos su información de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación).</li>
      <li>Oponerse al uso de sus datos personales para fines específicos (Oposición).</li>
    </ul>

    <br />
    <h5>Medios y Procedimiento para ejercer sus derechos ARCO:</h5>
    <p class="mb-2">
      Usted puede presentar la solicitud respectiva a través del siguiente medio:
    </p>
    <p>
      Enviando una solicitud al siguiente correo electrónico: juridico@confiop.com
    </p>
    <ul>
      <li>Formato de Solicitud para ejercer los derechos ARCO debidamente llenado.</li>
      <li>Identificación que lo acredite como titular de los derechos, y en su caso, los documentos que acrediten la representación legal del titular.</li>
      <li>En caso de solicitudes de rectificación de datos deberá indicarse las modificaciones que deberán realizarse y aportar la documentación que sustente la petición.</li>
      <li>Una cuenta de correo electrónico o medio por medio del cual se le notificara la contestación emitida.</li>
    </ul>

    <ol>
      <li>Una vez recibida su solicitud en ejercicio de sus Derechos ARCO se le comunicará la respuesta en un plazo no mayor a 20 (veinte) días naturales contados a partir de la fecha de recepción.</li>
      <li>En caso de resultar procedente la solicitud se hará efectiva dentro del plazo de 15 (quince) días naturales siguientes a la comunicación de la respuesta. </li>
    </ol>

    <p class="mb-2">
      *Es importante tenga en cuenta en caso de solicitudes de revocación, no en todos los casos podemos atender su solicitud y concluir el uso de manera inmediata, ya que es posible que por alguna obligación legal requiramos seguir tratando sus datos personales.
    </p>
    <p class="mb-2">
      Asimismo, deberá considerar que, para ciertos fines, la revocación de su consentimiento implicara que no le podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.
    </p>
    <p class="mb-2">
      *Con el objeto de que usted pueda limitar el uso de su información personal, se podrá inscribir a los siguientes Registros: -Registro Público para Evitar Publicidad (PROFECO). -Registro Público de Usuarios (CONDUSEF).
    </p>
    <p>
      Los datos de nuestro departamento de Datos Personales son los siguientes: Nombre: CONFIOP datos personales. Dirección: Av Elementia 125, Int 5  colonia Fraccionamiento Element, Tlajomulco de Zúñiga, Jalisco, CP 45645. Correo electrónico: juridico@confiop.com Número telefónico: +52 33 12 42 49 79
    </p>

    <br />
    <h5>El uso de tecnologías de rastreo en nuestro portal de Internet</h5>
    <p class="mb-2">
      En caso de acceso a nuestro portal de Internet, le informamos es posible nuestros sistemas recopilen datos del titular tales como tipo de navegador, sistema operativo, páginas de internet visitadas, dirección IP, etc., a través de “cookies” o “web beacons”, entre otras tecnologías.
    </p>
    <p class="mb-2">
      Asimismo, le informamos que los datos personales que se obtienen a través de estas tecnologías pueden ser usados con los fines a que se refiere el presente Aviso de Privacidad y podrán compartirse con las personas a que se hace referencia.
    </p>
    <p class="mb-2">
      Estas cookies y otras tecnologías pueden ser deshabilitadas. Puede buscar información sobre los navegadores conocidos y averiguar cómo ajustar las preferencias de las cookies en los siguientes sitios web: Microsoft Internet Explorer: http://www.microsoft.com/info/cookies.html Mozilla Firefox: http://www.mozilla.org/projects/security/pki/psm/help_21/using_priv_help.html
    </p>
    <p>
      En el caso de empleo de cookies, el botón de "ayuda" que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo evitar aceptar nuevas cookies, cómo hacer que el navegador le notifique cuando recibe un nuevo cookie o cómo deshabilitar todos los cookies.
    </p>

    <br />
    <h5>Imágenes y sonidos captados por cámaras de video-vigilancia</h5>
    <p class="mb-2">
      En alguna de las oficinas o sucursales de CONFIOP Reingeniería Financiera. se encuentran operando sistemas de video-vigilancia (“SVV” o “CCTV”), los cuales consisten en el uso de cámaras de video fijas, con o sin sonido, que se encuentran instaladas al interior o exterior, y cuya operación se encuentra limitada al monitoreo y supervisión del espacio que ocupan y de las personas que en él se encuentran.
    </p>
    <p>
      Mediante el uso de los “SVV o CCTV” CONFIOP Reingeniería Financiera. podrá obtener imágenes y en algunas ocasiones sonidos, que identifiquen a individuos o que los hagan identificables a partir de la asociación de otros “Datos” recabados. CONFIOP Reingeniería Financiera. hará el tratamiento de las imágenes y en algunas ocasiones sonidos captados por los “SVV o CCTV”, mediante el almacenamiento en dispositivos que posea para tal fin; dicho tratamiento únicamente será para fines de mantener y resguardar la seguridad de las instalaciones y de las personas que se encuentren en ellas.
    </p>

    <br />
    <h5>Modificaciones al Aviso de Privacidad</h5>
    <p>
      CONFIOP Reingeniería Financiera. podrá en cualquier momento realizar cambios al presente Aviso de Privacidad de acuerdo a modificaciones en nuestras políticas de privacidad y adaptarla a nuevos requerimientos legales, a las propias necesidades de los productos o servicios que se ofrecen, entre otras causas que se pudieren presentar. Estas modificaciones se encontrarán disponibles al público en nuestro portal de internet www.confiop.com  Se recomienda a los titulares visitar periódicamente nuestro sitio a fin de verificar la versión más actual de nuestro Aviso de Privacidad.
    </p>
  </div>
</template>

<script>
export default {
}
</script>
