<template lang="html">
  <div id="about-us-page">

    <section class="about-section">
      <div class="bg-iso"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-xl-5 align-self-center col-text">
            <h2 class="title-s1" data-aos="fade-down">Nuestra firma</h2>

            <h3 class="text-left subtitle-s1" data-aos="fade-down" data-aos-delay="100">Profesionalismo y eficacia combinadas con experiencia…</h3>

            <div data-aos="fade-down" data-aos-delay="200">
              <p class="mt-3 mt-sm-4 p-md">
                <strong class="f-w-600">CONFIOP®</strong> ayuda a sus clientes a lograr sus objetivos con efectividad y un alto sentido de ética. Nuestra firma se encuentra altamente especializada en áreas de Contabilidad, Finanzas y Operaciones.
              </p>
              <p class="mt-2 mt-xl-3 p-md">
                Buscamos la excelencia, y una relación de largo plazo con todos nuestros clientes, aliados y proveedores, para así formar un vínculo de negocio de verdadero valor que aporte al crecimiento de tu empresa en el corto y largo plazo.
              </p>
            </div>
          </div>

          <div class="col-lg-6 offset-xl-1 col-image" data-aos="fade-left">
            <img src="public/images/pages/about-us/image-1.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/image-1.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="history-section">
      <div class="content">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-lg-6 col-xl-5 col-image" data-aos="fade-up">
              <img src="public/images/pages/about-us/image-2.jpg">
            </div>

            <div class="col-lg-6 offset-xl-1 col-text" data-aos="fade-down">
              <h2 class="title-s1">Filosofía</h2>

              <p class="mt-2 mt-lg-4 text-justify p-lg">
                En Confiop® estamos comprometidos con la creatividad, el desarrollo sostenible y la eficiencia. La transparencia y la honestidad son pieza clave en nuestro día a día y bajo esos principios actuaremos en todo momento y te ayudaremos a crecer.
              </p>

              <p class="mt-3 text-justify p-lg">
                Vemos a nuestra firma como un equipo altamente especializado líder en su industria y posicionado como un grupo que resuelve y provee soluciones reales a todos los retos que se presentan en la operación y administración de una empresa.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
