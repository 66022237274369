<template lang="html">
  <div id="solutions-page">

    <section class="top-section-s1">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-text">
            <h1 class="title-s1" data-aos="fade-down">Finanzas</h1>
            <h2 class="subtitle-s1" data-aos="fade-down" data-aos-delay="100">Con un equipo multidisciplinario en materia financiera</h2>

            <div data-aos="fade-down" data-aos-delay="200">
              <p class="mt-3">
                Nuestro equipo multidisciplinario operará como una área mas de tu empresa, trabajando lado a lado con tu empresa y formando una alianza de confianza integral que te será de un gran apoyo para el crecimiento a corto, largo y mediano plazo.
              </p>
              <p class="mt-2">
                Nuestros servicios financieros se clasifican en las siguientes áreas:
              </p>
            </div>
          </div>

          <div class="col-lg-5 offset-lg-1 col-image" data-aos="fade-left">
            <img src="public/images/pages/solutions/finance-img.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/solutions/finance-img.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="buttons-section" id="buttons-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-button" data-aos="fade-up" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(1)" v-bind:class="{ 'active' : nSection == 1 }"><span>Finanzas de ejecución con perspectiva a futuro</span></a>
          </div>

          <div class="col-lg-6 col-button" data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(2)" v-bind:class="{ 'active' : nSection == 2 }"><span>Asesoría y Consultoría Financiera integral</span></a>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 1">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Finanzas de ejecución con perspectiva a futuro</h3>

          <div class="row align-items-start">
            <div class="col-lg-3 col-icon">
              <img class="img-icon" src="public/images/pages/solutions/icon-4.svg">
            </div>

            <div class="col-lg-9 col-text">
              <!-- <div class="img-float left">
                <img class="img-icon" src="public/images/pages/solutions/icon-4.svg">
              </div> -->

              <ol class="list-striped">
                <li class="txt-ugly-color1">
                  Realizamos una evaluación financiera integral de tu empresa para identificar tu situación real financiera creando un diagnóstico financiero y así, poder tomar decisiones asertivas en tiempo y forma.
                </li>
                <li>
                  Desarrollamos un presupuesto maestro (Budget) y modelamos tus Forecast que te proveerán de visión y perspectiva a corto, mediano y largo plazo, para estabilizarte y crecer.
                </li>
                <li>
                  Elaboramos los estados financieros (Estado de Resultados, Balance General, entre otros.) basados en las mejores prácticas financieras. Además, te los entregamos con la debida argumentación técnica y te explicamos de manera personalizada tus resultados periodo a periodo.
                </li>
                <li>
                  Crearemos el plan de flujo de efectivo para que cuentes con una estimación real del pulso de tu negocio.
                </li>
                <li>
                  Mediremos resultados financieros tomando como referencia tus objetivos y asesorando en cada paso.
                </li>
                <li>
                  Mediremos la situación actual y real de tu empresa mediante los ratios o índices financieros y determinaremos en conjunto planes de acción para que continues por un buen camino.
                </li>
                <li>
                  Desarrollaremos la estrategia financiera de tu empresa para así lograr el crecimiento controlado que marcará una diferencia.
                </li>
                <li>
                  Realizaremos evaluaciones a tus proyectos de inversión para determinar la viabilidad, rentabilidad y riesgos, siempre asistiéndote de manera presencial.
                </li>
                <li>
                  Analizaremos detenidamente los costos y gastos, de las diferentes áreas de tu empresa, identificando áreas de oportunidad y brindándote la asesoría para mejorarlas.
                </li>
                <li>
                  Haremos tus reportes financieros 100% personalizados, para que puedas visualizar la situación financiera de tu empresa.
                </li>
                <li>
                  Capacitamos a mandos ejecutivos, directivos y al equipo de finanzas. Para la interpretación y análisis de la información financiera.
                </li>
                <li>
                  Identificamos puntos críticos de control financieros, riesgos y áreas de oportunidad, y te ayudaremos a crear controles internos que salvaguardan tus recursos y te proveerán de mayor seguridad para continuar tu operación diaria.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 2">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Asesoría y Consultoría Financiera integral</h3>

          <div class="row align-items-start">
            <div class="col-lg-3 col-icon">
              <img class="img-icon" src="public/images/pages/solutions/icon-5.svg">
            </div>

            <div class="col-lg-9 col-text">
              <!-- <div class="img-float right">
                <img class="img-icon mw-75" src="public/images/pages/solutions/icon-5.svg">
              </div> -->

              <ol class="list-striped">
                <li>
                  Verificaremos tu BUDGET y FORECAST y lo evaluaremos para asesorarte sobre riesgos, debilidades, áreas de oportunidad y fortalezas, de tal que forma puedas realizar ajustes eficientes y continuar avanzando.
                </li>
                <li>
                  Proveeremos seguimiento periódico al estado financiero de tu empresa para asesorarte en tiempo real sobre la evolución de esta área.
                </li>
                <li>
                  Evaluaremos tus Estados de Resultados y Balance General periodo a periodo en reuniones cíclicas personalizadas, según el calendario de revisión estratégico, mediremos objetivos logrados, incidencias y nuevas ideas.
                </li>
                <li>
                  Te mantendremos asesorado en tiempo y forma sobre el comportamiento de tu flujo para prevenir adversidades. Además, estaremos siempre disponibles para atender dudas que surjan en tu operación.
                </li>
                <li>
                  Mediremos tus resultados financieros y realizaremos un acompañamiento hecho a la medida referente a la interpretación, asesorándote en la toma de decisiones.
                </li>
                <li>
                  Supervisamos la fluctuación de tus ratios o indicadores financieros para guiarte a una oportuna toma de decisiones.
                </li>
                <li>
                  Evaluaremos tu estrategia financiera para fortalecerla e impulsar tus resultados de forma efectiva.
                </li>
                <li>
                  Supervisamos las propuestas de los modelos de proyectos de inversión para garantizar su viabilidad.
                </li>
                <li>
                  Analizaremos tus costos y gastos para orientarte en hacerlos más eficientes.
                </li>
                <li>
                  Llevaremos a cabo la revisión de tus reportes financieros, para asesorarte en su mejora, identificando los puntos críticos que debemos de poner atención, proponiendo soluciones eficaces.
                </li>
                <li>
                  Te orientaremos en las diversas fuentes de financiamiento que se ajusten más a tus necesidades, guiándote en la creación del expediente necesario a entregar para la solicitud de los mismos.
                </li>
                <li>
                  Supervisamos mediante reuniones cíclicas tus procesos y procedimientos en materia financiera, identificando las posibles áreas de oportunidad que deban ser solucionadas y /o mejoradas.
                </li>
                <li>
                  Mediante un Couching personalizado, guiaremos y capacitaremos a tu equipo de trabajo administrativo y Financiero.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      nSection: 1,
    }
  },

  methods: {
    goToSection(idx) {
      this.nSection = idx;
      document.querySelector('#buttons-section').scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>
