<template lang="html">
  <div id="team-detail-v2-page">

    <div class="main-section" data-aos="fade">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-text">
            <div class="box-intro">
              <div class="col-lg col-image">
                <div class="box-photo">
                  <img src="public/images/pages/about-us/team-1-lg.jpg" alt="">
                </div>
              </div>

              <div class="col-12 col-info">
                <h1 class="mb-0 mt-3 text-center title-s1">Eduardo Pérez Hernández</h1>
                <!-- <p>
                  Ejecutivo con experiencia en áreas financiera, contable, administrativa y operativa, con 10 años de trayectoria realizando mediciones de rendimientos, elaboración y análisis de Estados Financieros, creación de modelos financieros, presupuestos corporativos, planeación estratégica y reestructuras de carácter Operativo – Financiero.
                </p>
                <h6 class="mt-3 mb-2 f-w-600"><i class="fas fa-envelope txt-icon"></i> ejemplo@email.com</h6>
                <h6 class="mb-4 f-w-600"><i class="fas fa-phone txt-icon"></i> 33 1000 5000</h6>

                <h6 class="f-w-700 h6-5">Universidad Autónoma de Madrid (UAM) (España)</h6>
                <h6 class="f-w-700">(2008 – 2009)</h6>
                <ol class="l-u-roman">
                  <li>
                    <h6 class="f-w-600">Máster en Dirección de Empresas Multinacionales.</h6>
                    <p>
                      Tesis: “Los Contratos por Diferencias (CFDs): Una Alternativa de Inversión para el Mercado Financiero de México” Nota: Notable.
                    </p>
                  </li>
                </ol>

                <h6 class="mt-4 f-w-700 h6-5">Centro Universitario de Ciencias Económico Administrativas (CUCEA).</h6>
                <h6 class="f-w-700">(2004 – 2007)</h6>
                <ol class="l-u-roman">
                  <li>
                    <h6 class="f-w-600">Licenciatura en Administración.</h6>
                    <p>
                      Documento adquirido: Titulación por nota sobresaliente.
                    </p>
                  </li>
                </ol> -->
              </div>
            </div>

            <div class="box-more-info">
              <!-- <h5 class="f-w-600 text-center txt-orange">Experiencia Laboral</h5>
              <hr class="mt-1 mb-2" />
              <h6 class="f-w-700">Administradora de Bienes Arrendados S.A. de C.V. (Servicios).</h6>
              <h6 class="pl-3">(Agosto 2020 – Actual)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Dirección de Administración y Finanzas.</li>
              </ul>

              <h6 class="f-w-700">Corporativo Producciones Son Del 11 (Servicios).</h6>
              <h6 class="pl-3">(Octubre 2019 – Julio 2020)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Director General.</li>
              </ul>

              <h6 class="f-w-700">CONFIOP Reingeniería Financiera (Autónomo).</h6>
              <h6 class="pl-3">(Junio 2019 – Actual)</h6>
              <ul class="pl-4 l-u-roman">
                <li>CEO y Fundador.</li>
              </ul>

              <h6 class="f-w-700">GRUPO BEGULA (Granjero Feliz, Vísceras Selectas del Bajío, entre otras Razones Sociales).</h6>
              <h6 class="pl-3">(Diciembre de 2014 – junio de 2019).</h6>
              <ul class="pl-4 l-u-roman">
                <li>Project Manager de Presidencia (Líder de Proyectos / Asesor de Presidencia del Grupo).</li>
                <li>Dirección de Planeación Corporativa, (Financiera y Estratégica)</li>
                <li>Líder de Proyecto Implementación del ERP (Proyecto en Paralelo).</li>
                <li>Gerente de Administración y Finanzas.
                  <ul class="l-t-disc">
                    <li>Unidad Empresarial de Negocios (Valores Agregados).</li>
                    <li>Unidad Empresarial de Negocios (Sucursales).</li>
                    <li>Unidad Empresarial de Negocios (Cerdos en Pie).</li>
                  </ul>
                </li>
              </ul>

              <h6 class="f-w-700">Corporativo Desarrollos Mile SA de CV (Idex Constructora).</h6>
              <h6 class="pl-3">(Abril 2014 – noviembre 2014)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Coordinador Financiero.</li>
              </ul>

              <h6 class="f-w-700">Corporativo Dynamica (Constructora).</h6>
              <h6 class="pl-3">(Enero 2013 – abril 2014)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Jefe de Planeación Financiera.</li>
              </ul>

              <h6 class="f-w-700">Empresas Tajín.</h6>
              <h6 class="pl-3">(Noviembre 2011 – enero 2013)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Analista Financiero.</li>
              </ul> -->

              <!--  -->
              <!-- <h5 class="mt-4 f-w-600 text-center txt-orange">Experiencia Laboral en el Extranjero</h5>
              <hr class="mt-1 mb-2" />
              <h6 class="f-w-700">Empresa Multinacional Carat. División Expert (Madrid, España).</h6>
              <h6 class="pl-3">(Enero 2010 – enero 2011)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Analista Expert.</li>
              </ul>

              <h6 class="f-w-700">Instituto de Predicción Económica L.R. Klein de la Universidad Autónoma de Madrid (Madrid, España).</h6>
              <h6 class="pl-3">(Marzo 2009 – enero 2010)</h6>
              <ul class="pl-4 l-u-roman">
                <li>Analista Investigador.</li>
              </ul> -->

              <!--  -->
              <!-- <h5 class="mt-4 f-w-600 text-center txt-orange">Publicaciones y Proyectos de Investigación en el Extranjero</h5>
              <hr class="mt-1 mb-2" />
              <h6 class="f-w-700">Madrid, España</h6>
              <ul class="pl-4 l-u-roman">
                <li>Anuario Latinoamericano Economía, Sociedad, Política y Medio Ambiente 2009 (ISSN: 1889-018). Como colaborador.</li>
                <li>DELFOS Predicciones Económicas Internacionales (ISSN: 11344229). Como colaborador.</li>
                <li>Colaborador en los proyectos: “Proyecto Observatorio de Economía Latinoamericana” (Red ECONOLATIN).  Y “Proyecto: Centro de Estudios Latinoamericanos” (CESLA).</li>
              </ul> -->


              <!--  -->
              <!-- <h5 class="mt-4 f-w-600 text-center txt-orange">Otros Estudios</h5>
              <hr class="mt-1 mb-2" />
              <ul class="pl-4 l-u-roman">
                <li>Inglés B1: Instituto Cultural Mexicano Norteamericano de Jalisco e interlingua.</li>
                <li>Diplomado: "Desarrollo de Habilidades Gerenciales: Líder de Líderes". (Tec. de Monterrey).</li>
                <li>Curso "Bolsa y otros Mercados Financieros". (Bolsa de Madrid España).</li>
                <li>Curso "Excel Avanzado" Centro Universitario de Ciencias Económico Administrativas (U de G).</li>
              </ul> -->


              <!--  -->
              <h5 class="f-w-600 text-center txt-orange">Áreas Empresariales de Expertise</h5>
              <hr class="mt-1 mb-2" />
              <h6 class="f-w-700">
                A) Finanzas<br />
                <span class="f-w-500 pl-1">10 años de experiencia (Principales Actividades)</span>
              </h6>
              <ul class="pl-4 l-u-roman">
                <li>Elaboración de estados financieros y su análisis, mediante modelos verticales, horizontales y razones Financieras.</li>
                <li>Elaboración y análisis del flujo de efectivo.</li>
                <li>Evaluación proyectos de inversión.</li>
                <li>Creación del presupuesto maestro.</li>
                <li>Análisis estadístico de los resultados vs budget y forecast.</li>
                <li>Implementación de reportes para presentar a la junta de consejo.</li>
                <li>Control presupuestario.</li>
                <li>Análisis de costos y gastos.</li>
                <li>Planeación financiera, entre otros.</li>
              </ul>

              <h6 class="f-w-700">
                B) Contabilidad<br />
                <span class="f-w-500 pl-1">9 años de experiencia (Principales Actividades)</span>
              </h6>
              <ul class="pl-4 l-u-alpha">
                <li>Revisión de resultados fiscales.</li>
                <li>Revisión de estrategia fiscal.</li>
                <li>Impuestos.</li>
                <li>Devoluciones de IVA.</li>
                <li>Seguimiento de auditorías internas y externas.</li>
                <li>Administración de activos y otros recursos.</li>
                <li>Análisis de costos.</li>
                <li>Análisis del Capital Social, entre otros…</li>
              </ul>

              <h6 class="f-w-700">
                C) Operaciones<br />
                <span class="f-w-500 pl-1">9 años de experiencia (Principales Actividades)</span>
              </h6>
              <ul class="pl-4 l-u-alpha">
                <li>Revisión de rendimientos.</li>
                <li>Análisis del “Realiz” del arte del negocio.</li>
                <li>Análisis de procesos productivos y reingeniería de procesos.</li>
                <li>Creación de Diagramas y Manuales de Procesos.</li>
                <li>Seguimiento y revisión de Calidad.</li>
                <li>Apoyo en reestructura de procesos, según certificaciones gubernamentales.</li>
                <li>Inventarios cíclicos.</li>
                <li>Programación de producción.</li>
                <li>Manejo y coordinación de personal operativo / producción, entre Otros…</li>
              </ul>

              <h6 class="f-w-700">
                D) Capital Humano<br />
                <span class="f-w-500 pl-1">7 años de experiencia (Principales Actividades)</span>
              </h6>
              <ul class="pl-4 l-u-alpha">
                <li>Plan de desarrollo organizacional</li>
                <li>Organigramas y descripciones de puesto</li>
                <li>Revisión de cálculos de nómina</li>
                <li>Elaboración de la nómina</li>
                <li>Análisis de impuestos.</li>
                <li>Análisis de Rotación.</li>
                <li>Análisis de ausentismo, entre otros…</li>
              </ul>

              <h6 class="f-w-700">
                E) Comercial<br />
                <span class="f-w-500 pl-1">6 años de experiencia (Principales Actividades)</span>
              </h6>
              <ul class="pl-4 l-u-alpha">
                <li>Revisión de la estrategia comercial y sus resultados reales vs presupuesto</li>
                <li>Posicionamiento de marca.</li>
                <li>Análisis de ventas y de mercado.</li>
                <li>Análisis de Gastos.</li>
                <li>Estrategia de Comisiones.</li>
                <li>Cálculo de precios de transferencia y de venta, entre otros.</li>
              </ul>

              <h6 class="f-w-700">
                F) Competencias Desarrolladas
              </h6>
              <ul class="l-t-circle">
                <li class="f-w-500">Liderazgo</li>
                <li class="f-w-500">Orientado a resultados</li>
                <li class="f-w-500">Responsable</li>
                <li class="f-w-500">Trabajo en equipo</li>
                <li class="f-w-500">Proactivo</li>
                <li class="f-w-500">Alto nivel de negociación</li>
                <li class="f-w-500">Analítico</li>
                <li class="f-w-500">Empático</li>
                <li class="f-w-500">Buena comunicación oral y escrita</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
}
</script>
