<template lang="html">
  <div id="home-page">

    <section class="banner-section" data-aos="fade">
      <swiper class="swiper swiper-s1 blue desktop" :options="bannersOptions">
        <swiper-slide v-for="(b, bhInx) in banners" :key="'bhInx-'+bhInx">
          <a class="placed-backg box" @click="$root.showCompanyModal = true;" v-bind:style="{ backgroundImage: 'url('+b.imageUrl+')' }">
            <img src="public/images/pages/home/banner.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>

      <swiper class="swiper swiper-s1 blue mobile" :options="bannersOptions">
        <swiper-slide v-for="(b, bhmInx) in banners" :key="'bhmInx-'+bhmInx">
          <a class="placed-backg box" @click="$root.showCompanyModal = true;" v-bind:style="{ backgroundImage: 'url('+b.imageMobUrl+')' }">
            <img src="public/images/pages/home/banner-m.png">
          </a>
        </swiper-slide>

        <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
      </swiper>
    </section>

    <section class="about-section" data-aos="fade">
      <div class="content">
        <div class="container oversized-container">
          <div class="row">
            <div class="col-md-5 col-xl-6 col-title">
              <h2>Acerca de</h2>
            </div>

            <div class="col-md-7 col-xl-6 col-text">
              <p>
                CONFIOP® es una firma de alta especialización, con experiencia multinacional en planeación, ejecución, reingeniería de estrategias y análisis financiero, contable y en operaciones para empresas de toda industria.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="options-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 col-opt" data-aos="fade-up" data-aos-offset="50">
            <router-link class="box-opt t-250 blue" to="/contabilidad">
              <h4>Contabilidad</h4>
            </router-link>
          </div>

          <div class="col-lg-4 col-opt" data-aos="fade-up" data-aos-delay="200" data-aos-offset="50">
            <router-link class="box-opt t-250 lilac" to="/finanzas">
              <h4>Finanzas</h4>
            </router-link>
          </div>

          <div class="col-lg-4 col-opt" data-aos="fade-up" data-aos-delay="400" data-aos-offset="50">
            <router-link class="box-opt t-250 gray" to="/operaciones">
              <h4>Operaciones</h4>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banners: [
        { imageUrl: 'public/images/pages/home/banner-9.jpg', imageMobUrl: 'public/images/pages/home/banner-9-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-10.jpg', imageMobUrl: 'public/images/pages/home/banner-10-m.jpg' },
        { imageUrl: 'public/images/pages/home/banner-11.jpg', imageMobUrl: 'public/images/pages/home/banner-11-m.jpg' },
      ],

      // == Carousel options ==
      bannersOptions: {
        effect: 'fade',
        loop: false,
        speed: 700,

        autoplay: {
          delay: 4000,
          disableOnInteraction: false
        },

        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
      // == ==
    }
  },
}
</script>
