<template lang="html">
  <div id="industries-page">

    <section class="top-section-s1">
      <div class="bg-iso"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-image" data-aos="fade-right">
            <img class="f-right" src="public/images/pages/industries/tech-software-img.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/industries/tech-software-img.jpg)' }"></div>
          </div>

          <div class="col-lg-6 offset-lg-1 col-text">
            <h1 class="title-s1" data-aos="fade-down">Industrias tecnológicas y de software</h1>
            <h2 class="mt-3 subtitle-s1 text-justify" data-aos="fade-down" data-aos-delay="100">Soluciones ágiles y simples, orientados a la creciente industria del software, que permitirán llevar el control de tus procesos y orden en tu firma.</h2>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-text">
            <h3 class="mt-4 mt-lg-5 pt-lg-3 mb-lg-3 title" data-aos="fade-up" data-aos-offset="100">Soluciones para esta industria</h3>
            <ul class="txt-lg" data-aos="fade-up" data-aos-delay="150" data-aos-offset="100">
              <li>
                Asesoría tributaria para optimizar tus costos operativos.
              </li>
              <li>
                Consultoría contable que permitirá cuentas sanas.
              </li>
              <li>
                Actualización en materia fiscal.
              </li>
              <li>
                Seguimiento a estrategias fiscales y acompañamiento año con año.
              </li>
            </ul>
          </div>

          <div class="col-lg-5 offset-lg-1 col-image" data-aos="fade-left" data-aos-offset="200">
            <div class="placed-backg img" v-bind:style="{ backgroundImage: 'url(public/images/pages/industries/tech-software-2-img.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
