import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
	    { path: '/', component: require(page+'home/index.vue').default },
      { path: '/aviso-de-privacidad', component: require(page+'text-pages/aviso.vue').default, meta:{title:"Aviso de privacidad"}},

      { path: '/nuestra-firma', component: require(page+'about-us/about-us.vue').default, meta:{title:"Nuestra firma"}},
      // { path: '/casos-de-exito', component: require(page+'about-us/success-stories.vue').default, meta:{title:"Casos de éxito"}},
      // { path: '/casos-de-exito/:id', component: require(page+'about-us/success-stories-detail.vue').default, meta:{title:"Casos de éxito"}},
      { path: '/equipo-de-trabajo', component: require(page+'about-us/team.vue').default, meta:{title:"Equipo de trabajo"}},
      { path: '/equipo-de-trabajo/:id', component: require(page+'about-us/team-detail-v2.vue').default, meta:{title:"Equipo de trabajo"}},

      { path: '/contabilidad', component: require(page+'solutions/accounting.vue').default, meta:{title:"Contabilidad"}},
      { path: '/finanzas', component: require(page+'solutions/finance.vue').default, meta:{title:"Finanzas"}},
      { path: '/operaciones', component: require(page+'solutions/operations.vue').default, meta:{title:"Operaciones"}},

      { path: '/tecnologia-y-software', component: require(page+'industries/tech-software.vue').default, meta:{title:"Tecnología y software"}},

      { path: '/contacto', component: require(page+'contact/index.vue').default, meta:{title:"Contacto"}},

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	  ]
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},500);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "CONFIOP. Consultoría contable, financiera y en operaciones.", filter: (title)=>{ return title+" - CONFIOP. Consultoría contable, financiera y en operaciones"; } }
);

// export {routes};
export default MyRouter;
