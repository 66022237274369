<template lang="html">
  <header id="header">
    <div class="header-content">
      <b-navbar toggleable="lg" type="light" variant="light">
        <div class="container oversized-container">
          <b-navbar-brand to="/">
            <img src="public/images/logo.svg" />
          </b-navbar-brand>

          <div class="box-extra">
            <a class="t-150" @click="$root.showCompanyModal = true;">AUTOEVALÚATE</a>
          </div>
          <b-navbar-toggle target="nav-collapse"><i class="fas fa-bars"></i></b-navbar-toggle>

          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>NOSOTROS</span>
                </template>
                <b-dropdown-item to="/nuestra-firma">Nuestra firma</b-dropdown-item>
                <!-- <b-dropdown-item to="/casos-de-exito">Casos de éxito</b-dropdown-item> -->
                <b-dropdown-item to="/equipo-de-trabajo">Equipo de trabajo</b-dropdown-item>
              </b-nav-item-dropdown>

              <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>SOLUCIONES</span>
                </template>
                <b-dropdown-item to="/contabilidad">Contabilidad</b-dropdown-item>
                <b-dropdown-item to="/finanzas">Finanzas</b-dropdown-item>
                <b-dropdown-item to="/operaciones">Operaciones</b-dropdown-item>
              </b-nav-item-dropdown>

              <!-- <b-nav-item-dropdown class="simple-item" right>
                <template #button-content>
                  <span>INDUSTRIAS</span>
                </template>
                <b-dropdown-item to="">Energética</b-dropdown-item>
                <b-dropdown-item to="">Ingeniería y construcción</b-dropdown-item>
                <b-dropdown-item to="">Retail y de consumo</b-dropdown-item>
                <b-dropdown-item to="">Salud</b-dropdown-item>
                <b-dropdown-item to="">Industrial</b-dropdown-item>
                <b-dropdown-item to="/tecnologia-y-software">Tecnología y software</b-dropdown-item>
                <b-dropdown-item to="">Transporte</b-dropdown-item>
              </b-nav-item-dropdown> -->
              <b-nav-item class="simple-item" to="/contacto">CONTACTO</b-nav-item>
              <li class="nav-item btn-item bg-orange d-none d-lg-inline-block" @click="$root.showCompanyModal = true;">
                <a class="nav-link" target="_self">AUTOEVALÚA TU EMPRESA</a>
              </li>
              <!-- <li class="nav-item btn-item bg-blue">
                <a class="nav-link" target="_blank" download href="public/pdfs/brochure.pdf">BROCHURE CORPORATIVO</a>
              </li> -->
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
    </div>

    <!-- Modal "explora tu empresa" -->
    <CompanyFormModal v-if="$root.showCompanyModal"></CompanyFormModal>
    <!--  -->
  </header>
</template>

<script>
import CompanyFormModal from './company-form-modal.vue';
export default {
  components: {
    CompanyFormModal
  },

  data() {
    return {}
  }
}
</script>
