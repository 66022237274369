<template lang="html">
  <footer id="footer">
		<div class="container oversized-container">
			<div class="row">
				<div class="col-xl-3 col-logo">
					<router-link to="/">
						<img src="public/images/logo-white-2.svg" alt="CONFIOP">
					</router-link>
				</div>

        <div class="col-xl-9 col-info">
          <div class="row">
            <div class="col-sm-6 col-md-4 col-lg col-footer text-center text-md-left text-xl-center">
              <p><strong class="f-w-500">CONFIOP</strong>, Todos los derechos reservados.</p>
              <!-- <p class="mt-2">
                Bosques de la Victoria, Diamante #2680,<br />
                Zapopan, Jalisco
              </p> -->
            </div>

            <div class="col-sm-6 col-md-4 col-lg col-footer text-center">
              <h6 class="mb-2 title">Información de contacto</h6>

              <p>
                Teléfono de atención al cliente:<br />
                33 1242 4979
              </p>
            </div>

            <div class="col-md-4 col-lg col-footer col-help text-center text-md-right">
              <h6 class="mb-2 title">Legal</h6>

              <!-- <p class="mb-2">
                <router-link class="link-sm" to="">Ayuda</router-link>
              </p> -->
              <p class="mb-2">
                <router-link class="link-sm" to="/aviso-de-privacidad">Aviso de privacidad</router-link>
              </p>
              <!-- <p>
                <router-link class="link-sm" to="">Términos y condiciones</router-link>
              </p> -->
            </div>

            <!-- <div class="col-lg-2 col-footer text-center">
              <a class="t-150 mr-3 btn-network" href="#">
                <i class="fab fa-instagram-square"></i>
              </a>

              <a class="t-150 btn-network" href="#">
                <i class="fab fa-facebook-square"></i>
              </a>
            </div> -->
          </div>
        </div>
			</div>
		</div>
  </footer>
</template>

<script>
export default {
}
</script>

<style lang="css" scoped>
</style>
