<template lang="html">
  <div id="solutions-page">

    <section class="top-section-s1">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-text">
            <h1 class="title-s1" data-aos="fade-down">Contabilidad</h1>
            <h2 class="subtitle-s1" data-aos="fade-down" data-aos-delay="100">Asesoría tributaria funcional y simplificada</h2>

            <p class="mt-3" data-aos="fade-down" data-aos-delay="200">
              En <strong class="f-w-600">CONFIOP®</strong> somos expertos contables con amplia experiencia en empresas, firmas y particulares de todo tamaño. Hemos clasificado nuestros servicios en 3 áreas que nos permitirán proteger tu operación contable y proveerte una tranquilidad real y sustentada.
            </p>
          </div>

          <div class="col-lg-5 offset-lg-1 col-image" data-aos="fade-left">
            <img src="public/images/pages/solutions/accounting-img.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/solutions/accounting-img.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="buttons-section" id="buttons-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-4 col-button" data-aos="fade-up" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(1)" v-bind:class="{ 'active' : nSection == 1 }"><span>Asesoría en Contabilidad Tributaria</span></a>
          </div>

          <div class="col-lg-4 col-button" data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(2)" v-bind:class="{ 'active' : nSection == 2 }"><span>Contabilidad general e impuestos</span></a>
          </div>

          <div class="col-lg-4 col-button" data-aos="fade-up" data-aos-delay="400" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(3)" v-bind:class="{ 'active' : nSection == 3 }"><span>Auditoría Interna</span></a>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 1">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Asesoría en Contabilidad Tributaria</h3>

          <div class="row">
            <div class="col-lg-3 col-icon">
              <!-- <i class="fal fa-sticky-note icon"></i> -->
              <img class="img-icon" src="public/images/pages/solutions/icon-1.svg">
            </div>

            <div class="col-lg-9 col-text">
              <ol class="list-striped">
                <li>
                  Nuestro equipo de especialistas en materia contable creará a la medida de tu empresa las estrategias fiscales, siempre cumpliendo de forma ética y profesional las normas gubernamentales.
                </li>
                <li>
                  Asesoría y consultoría 100% personalizada en las áreas contable, fiscal y administrativa de tu empresa.
                </li>
                <li>
                  Seguimiento integral de las estrategias fiscales implementadas, ofreciendo acompañamiento con tu junta directiva para la revisión del adecuado desempeño y ejecución de éstas.
                </li>
                <li>
                  Capacitación y orientación continua al personal contable y administrativo. Además de un soporte operativo siempre disponible para resolver cualquier duda que surja en el día a día.
                </li>
                <li>
                  Actualización fiscal sustentada, ofreciendo revisiones continuas para que tu empresa se encuentre al día, aplicando y cumpliendo con los cambios estipulados.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 2">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Contabilidad general e impuestos</h3>

          <div class="row">
            <div class="col-lg-3 col-icon">
              <img class="img-icon" src="public/images/pages/solutions/icon-2.svg">
            </div>

            <div class="col-lg-9 col-text">
              <ol class="list-striped">
                <li>
                  Realizamos de forma efectiva, profesional y digitalizada de la contabilidad de tu empresa, así mismo, hacemos el cálculo y la determinación de impuestos por cada periodo.
                </li>
                <li>
                  Elaboración y cálculo de la nómina, con base a La Ley Federal del Trabajo y todas las normas legales, para la determinación del pago correspondiente a tus empleados y a las entidades gubernamentales.
                </li>
                <li>
                  Revisión y supervisión continua de la correcta facturación de tu empresa hacia tus clientes y de tus proveedores hacia tu negocio.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section pt-1" v-if="nSection == 3">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Auditoría Interna</h3>

          <div class="row">
            <div class="col-lg-3 col-icon">
              <img class="img-icon" src="public/images/pages/solutions/icon-3.svg">
            </div>

            <div class="col-lg-9 col-text">
              <ol class="list-striped">
                <li>
                  Auditoría especializada a la medida de tu negocio y con sustento práctico, además de la presentación de reportes funcionales sobre las incidencias y áreas de oportunidad encontradas que te permitirán contar con una guía útil para realizar acciones de mejora y optimización.
                </li>
                <li>
                  Asesoría y acompañamiento en el cumplimiento de estándares relacionados a términos de auditoría.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      nSection: 1,
    }
  },

  methods: {
    goToSection(idx) {
      this.nSection = idx;
      document.querySelector('#buttons-section').scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>
