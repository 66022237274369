<template lang="html">
  <b-modal modal-class="modal-form" ref="modal-company" size="lg" title="" @hidden="destroyComponent()" hide-footer centered>
    <b-form @submit="onSubmit">
      <!-- Paso 1 -->
      <div class="row mx-0" v-if="step == 1">
        <div class="b-title-forzado">
          <h4 class="mb-2 f-w-300">LLena tus datos</h4>
        </div>

        <div class="col-lg-12">
          <b-form-group class="custom-group-s1" label="Nombre completo">
            <b-form-input
              v-model="form.name"
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s1" label="Correo electrónico">
            <b-form-input
              v-model="form.email"
              type="email"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-6">
          <b-form-group class="custom-group-s1" label="Teléfono">
            <b-form-input
              v-model="form.phone"
              type="text"
              minlength="10"
              maxlength="10"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-lg-12">
          <b-form-group class="custom-group-s1" label="Empresa">
            <b-form-input
              v-model="form.company"
              type="text"
              placeholder=""
              required
            ></b-form-input>
          </b-form-group>
        </div>

        <div class="col-12 mt-4 text-center">
          <b-button type="submit" class="btn-s1">Siguiente</b-button>
        </div>
      </div>
      <!--  -->

      <!-- Paso 2 -->
      <div class="row align-items-center mx-0" v-bind:class="{ 'loading' : loadingStep }" v-if="step == 2">
        <div class="b-title-forzado">
          <h4 class="mb-2 f-w-300">Autoevalua tu empresa</h4>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 1">
          <span class="txt-step-n">1. </span>
          <b-form-group class="custom-f-group-s2" label="¿Sabes exactamente cuál es la utilidad de tu empresa?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[0]"
              name="radio-q1">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(0, 'si', 2)">SI</a>
              <a class="btn-ans" @click="nextQuestion(0, 'no', 2)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 2">
          <span class="txt-step-n">2. </span>
          <b-form-group class="custom-f-group-s2" label="¿Tienes Presupuesto a 3 años como mínimo y revisas los resultados reales vs presupuesto?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[1]"
              name="radio-q2">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(1, 'si', 3)">SI</a>
              <a class="btn-ans" @click="nextQuestion(1, 'no', 3)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 3">
          <span class="txt-step-n">3. </span>
          <b-form-group class="custom-f-group-s2" label="¿Conoces cuánto dinero dejaste de ganar por ineficiencias?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[2]"
              name="radio-q3">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(2, 'si', 4)">SI</a>
              <a class="btn-ans" @click="nextQuestion(2, 'no', 4)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 4">
          <span class="txt-step-n">4. </span>
          <b-form-group class="custom-f-group-s2" label="¿Has definido cómo llegarás a los objetivos de la empresa?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[3]"
              name="radio-q4">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(3, 'si', 5)">SI</a>
              <a class="btn-ans" @click="nextQuestion(3, 'no', 5)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 5">
          <span class="txt-step-n">5. </span>
          <b-form-group class="custom-f-group-s2" label="¿Cuentas con un plan o estrategia Fiscal para el año en curso?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[4]"
              name="radio-q5">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(4, 'si', 6)">SI</a>
              <a class="btn-ans" @click="nextQuestion(4, 'no', 6)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 6">
          <span class="txt-step-n">6. </span>
          <b-form-group class="custom-f-group-s2" label="¿Ya Asignaste el % de reducción de egresos sobre los ingresos?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[5]"
              name="radio-q6">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(5, 'si', 7)">SI</a>
              <a class="btn-ans" @click="nextQuestion(5, 'no', 7)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 7">
          <span class="txt-step-n">7. </span>
          <b-form-group class="custom-f-group-s2" label="¿Sabes quién podrá realizar la dirección general durante tu jubilación o ausencia prolongada?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[6]"
              name="radio-q7">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(6, 'si', 8)">SI</a>
              <a class="btn-ans" @click="nextQuestion(6, 'no', 8)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 8">
          <span class="txt-step-n">8. </span>
          <b-form-group class="custom-f-group-s2" label="¿Controlas todos tus procesos identificando tus puntos críticos de control?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[7]"
              name="radio-q8">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(7, 'si', 9)">SI</a>
              <a class="btn-ans" @click="nextQuestion(7, 'no', 9)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 9">
          <span class="txt-step-n">9. </span>
          <b-form-group class="custom-f-group-s2" label="¿La calidad en tus productos y/o servicios son competitivas ante la competencia?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[8]"
              name="radio-q9">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(8, 'si', 10)">SI</a>
              <a class="btn-ans" @click="nextQuestion(8, 'no', 10)">NO</a>
            </p>
          </b-form-group>
        </div>

        <div class="col-12 col-lg-6 col-question" v-if="qNum == 10">
          <span class="txt-step-n">10. </span>
          <b-form-group class="custom-f-group-s2" label="¿Sabes como medir los resultados y el desempeño de tus empleados?">
            <b-form-radio-group required
              class="radios-group-s2"
              v-model="form.q[9]"
              name="radio-q10">
              <!-- <b-form-radio value="si"><strong class="f-w-800">SI</strong></b-form-radio>
              <b-form-radio value="no"><strong class="f-w-800">NO</strong></b-form-radio> -->
            </b-form-radio-group>
            <p>
              <a class="btn-ans" @click="nextQuestion(9, 'si', 11)">SI</a>
              <a class="btn-ans" @click="nextQuestion(9, 'no', 11)">NO</a>
            </p>
          </b-form-group>
        </div>

        <!-- Col Tacometro -->
        <div class="col-12 col-lg-6 col-tachimetro">
          <!-- <div class="box-tachimetro-s1">
            <h5 class="txt-title">Medidor <strong>CONFIOP</strong></h5>
            <img :src="'public/images/pages/home/tacometro-'+no+'-mini.png'">
            <h3 class="txt-score">{{ 10 * no }}</h3>
          </div> -->

          <div class="box-tachimetro-s2">
            <h5 class="mb-4 txt-title">Medidor <strong>CONFIOP</strong></h5>
            <img class="tachometer" src="public/images/pages/home/tachometer.svg">
            <img class="needle" src="public/images/pages/home/tachometer-needle.svg" v-bind:style="{ transform: 'rotate('+needlePosition+'deg)' }">
          </div>
        </div>
        <!--  -->

        <div class="col-12 mt-4 text-center col-nav">
          <b-button class="mx-2 btn-s2" @click="previewBtn" v-if="qNum >= 2">
            <span>Anterior</span>
          </b-button>
          <b-button type="submit" class="mx-2 btn-s1" v-if="qNum == 10">
            <!-- <span>Siguiente</span> -->
            <span class="f-w-600">Finalizar</span>
          </b-button>
        </div>
      </div>
      <!--  -->

      <!-- Paso 3 -->
      <div class="row mx-0" v-if="step == 3">
        <div class="b-title-forzado">
          <h4 class="mb-2 f-w-300">Cuestionario finalizado</h4>
        </div>

        <div class="col-12 text-center">
          <h2 class="mb-3 f-w-800">¡GRACIAS!</h2>

          <div class="box-tachimetro-s3">
            <img class="tachometer" src="public/images/pages/home/tachometer.svg">
            <img class="needle" src="public/images/pages/home/tachometer-needle.svg" v-bind:style="{ transform: 'rotate('+needlePosition+'deg)' }">
          </div>

          <div class="d-block mt-5 pt-2">
            <h5 :class="'txt-result '+classCSS">
              {{ form.result }}
            </h5>

            <router-link :class="'btn btn-s1 f-w-600 btn-contact '+classCSS" to="/contacto">¡CONTÁCTANOS AHORA!</router-link>
          </div>
        </div>
      </div>
      <!--  -->
    </b-form>
  </b-modal>
</template>

<script>
export default {
  data() {
    return {
      step: 2,
      qNum: 1,

      form: {
        name: null,
        email: null,
        phone: null,
        company: null,
        result:null,
        q: []
      },

      si: 0,
      no: 0,
      needlePosition: 0,
      classCSS: null,
      percentNo:0,
      loadingStep: false,
    }
  },

  methods: {
    fakeLoading() {
      this.loadingStep = true;

      setTimeout(()=> {
        this.loadingStep = false;
      }, 500);
    },

    nextQuestion(position, value, nQuestion) {
      this.form.q[position] = value;
      this.qNum = (nQuestion <= 10) ? nQuestion : 10;

      this.calculateTotals();

      if(nQuestion == 11) {
        // Paso 3
        this.step = 3;
        axios.post(tools.url("/api/assessments"),this.form).then((response)=>{
          }).catch((error)=>{
        });
      }
    },

    onSubmit(event) {
      event.preventDefault();

      // Paso 2
      if(this.step == 2) {
        this.calculateTotals();

        if(this.qNum == 10) {
          // Paso 3
          this.step = 3;
					axios.post(tools.url("/api/assessments"),this.form).then((response)=>{
				    }).catch((error)=>{
				  });
        }
        else {
          this.qNum++
        }
      }

      // Paso 1
      if(this.step == 1) {
        this.fakeLoading();
        this.step = 2;
      }
    },

    previewBtn(){
      this.qNum--;
      this.calculateTotals();
    },

    calculateTotals(){
      this.fakeLoading();
      let sicount = 0;
      let nocount = 0;

      for (let x= 0; x < this.form.q.length; x++) {
        if (this.form.q[x] == 'si') {
          sicount++;
        }
        else {
          nocount++;
        }
      }

      this.si = sicount;
      this.no = nocount;
      this.percentNo = (nocount * 100)/10;

      // == Aguja del tacómetro ==
      let siPorcent = sicount * 18;
      let noPorcent = nocount * 18;
      let position = noPorcent - siPorcent;

      if(position >= -90 && position <= 90){
        this.needlePosition = position;
      }
      // == fin aguja ==

      if (this.percentNo < 40) {
        this.classCSS = 'good';
        this.form.result = '¡Vas muy bien! Para mayor información contáctanos.';
      }
      else if(this.percentNo >= 40 && this.percentNo <= 59){
        this.classCSS = 'regular';
        this.form.result = '¡No esta mal!, pero puedes mejorar. ¡Contáctanos!';
      }
      else if(this.percentNo > 59){
        this.classCSS = 'bad';
        this.form.result = 'Parece que tienes problemas, ¡Te podemos ayudar!';
      }
    },

    destroyComponent() {
      this.$root.showCompanyModal = false;
    }
  },

  mounted() {
    this.$refs['modal-company'].show();
  },

  watch:{
    '$route' (to, from){
      this.$refs['modal-company'].hide();
    }
  },
}
</script>
