<template lang="html">
  <div id="team-page">

    <section class="top-section-s1">
      <div class="bg-iso"></div>

      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-5 col-image" data-aos="fade-right">
            <img class="f-right" src="public/images/pages/about-us/teamwork.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/about-us/teamwork.jpg)' }"></div>
          </div>

          <div class="col-lg-6 offset-lg-1 col-text">
            <h1 class="title-s1" data-aos="fade-down">Equipo de trabajo</h1>
            <h2 class="mt-3 subtitle-s1" data-aos="fade-down" data-aos-delay="100">Somos un equipo de gente experimentada y con ganas de ayudarte...</h2>
          </div>
        </div>
      </div>
    </section>

    <section class="team-section">
      <div class="content">
        <div class="bg"></div>

        <div class="container oversized-container">
          <div class="row justify-content-center">
            <!-- <div class="col-12 col-carousel" data-aos="fade-up" data-aos-offset="300">
              <swiper class="swiper" :options="carouselOptions">
                <swiper-slide v-for="(t, cInx) in team" :key="'cInx-'+cInx">
                  <div class="col-12 px-0 col-person">
                    <router-link class="box-person" to="/equipo-de-trabajo/1">
                      <div class="placed-backg box-image" v-bind:style="{ backgroundImage: 'url('+t.imgUrl+')' }">
                        <img src="public/images/pages/about-us/brand.png">
                      </div>

                      <div class="box-descr">
                        <h5 class="name">{{ t.name }}</h5>
                        <h6 class="location">{{ t.loc }}</h6>
                      </div>
                    </router-link>
                  </div>
                </swiper-slide>

                <div class="swiper-pagination" slot="pagination"></div>
              </swiper>
            </div> -->

            <div class="col-lg-6 col-person-v2" v-for="(t, tInx) in team" :key="'tInx-'+tInx">
              <router-link class="t-150 box-person" to="/equipo-de-trabajo/1">
                <div class="col placed-backg box-photo" v-bind:style="{ backgroundImage: 'url('+t.imgUrl+')' }"></div>

                <div class="col box-info">
                  <h5 class="name">{{ t.name }}</h5>
                  <h6 class="degree">{{ t.degree }}</h6>
                  <h6 class="mb-2 extra email"><i class="far fa-envelope icon"></i> {{ t.email }}</h6>
                  <h6 class="extra"><i class="fas fa-phone-alt icon"></i> {{ t.phone }}</h6>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      team: [
        { id: 1, imgUrl: 'public/images/pages/about-us/team-1.jpg', name: 'Eduardo Pérez Hernández', phone: '33 1242 4979', email: 'eduardo@confiop.com', degree: 'Dirección de Empresas Multinacionales' },
      ],

      // == Carousel options ==
      carouselOptions: {
        slidesPerView: 2,
        spaceBetween: 30,

        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },

        breakpoints: {
          992: {
            slidesPerView: 2
          },
          768: {
            slidesPerView: 2
          },
          1: {
            slidesPerView: 1
          },
        }
      }
      // == ==
    }
  }
}
</script>
