<template lang="html">
  <div id="solutions-page">

    <section class="top-section-s1">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-text">
            <h1 class="title-s1" data-aos="fade-down">Operaciones</h1>
            <h2 class="subtitle-s1" data-aos="fade-down" data-aos-delay="100">Efectivo monitoreo real en sus operaciones diarias</h2>

            <div data-aos="fade-down" data-aos-delay="200">
              <p class="mt-3">
                Contamos con personal especializado en operaciones de diferentes industrias, que permitirán a tu empresa contar con monitoreo real en sus operaciones diarias orientado a la efectividad, reducción de costos y control integral.
              </p>
              <p class="mt-2">
                Nuestra división de operaciones se clasifica de la siguiente forma:
              </p>
            </div>
          </div>

          <div class="col-lg-5 offset-lg-1 col-image" data-aos="fade-left">
            <img src="public/images/pages/solutions/operations-img.jpg">
            <div class="fake-img" v-bind:style="{ backgroundImage: 'url(public/images/pages/solutions/operations-img.jpg)' }"></div>
          </div>
        </div>
      </div>
    </section>

    <section class="buttons-section" id="buttons-section">
      <div class="container oversized-container">
        <div class="row">
          <div class="col-lg-6 col-button" data-aos="fade-up" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(1)" v-bind:class="{ 'active' : nSection == 1 }"><span>Operaciones</span></a>
          </div>

          <div class="col-lg-6 col-button" data-aos="fade-up" data-aos-delay="200" data-aos-offset="-200">
            <a class="_btn" @click="goToSection(2)" v-bind:class="{ 'active' : nSection == 2 }"><span>Gobierno Corporativo</span></a>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 1">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Operaciones</h3>

          <div class="row">
            <div class="col-lg-3 col-icon">
              <!-- <i class="fal fa-sticky-note icon"></i> -->
              <img class="img-icon mw-240" src="public/images/pages/solutions/icon-6.svg">
            </div>

            <div class="col-lg-9 col-text">
              <ol class="list-striped">
                <li>
                  Asesoría en la selección e implementación de sistemas de gestión para las diferentes áreas de tu organización; desde el diseño de los alcances del mismo hasta la adaptabilidad por parte de los miembros de tu organización.
                </li>
                <li>
                  Auditoría en procesos y procedimientos internos, en todas las áreas de la empresa.
                </li>
                <li>
                  Identificaremos los puntos críticos de control, así puedas ejercer acciones correctivas en ellos y garantizar la eficiencia y productividad, también te proveeremos seguimiento y medición continua a estas acciones para determinar el curso correcto de la estrategia a seguir.
                </li>
                <li>
                  Reingeniería de procesos, orientada a costos, calidad, servicio y eficiencia entre otros. Basado en una reconfiguración de las actividades y reinvención orientada a la mejora continua.
                </li>
                <li>
                  Revisión integral de la documentación de la empresa; manuales, políticas, procesos y procedimientos, ayudándote mediante nuestro equipo de expertos a actualizarla y optimizarla, implementando el Sistema de Gestión de Calidad.
                </li>
                <li>
                  En caso de no contar con un Sistema de Gestión, nosotros crearemos tu SG- CONFIOP (Sistema de Gestión CONFIOP), sistema probado que te ayudará a proveer un mejor servicio a tus clientes, mejor calidad en tus productos o servicios, fomentar una calidad de vida laboral a tus trabajadores y convertirte en una organización institucionalizada.
                </li>
                <li>
                  Seguimiento y medición del SG-CONFIOP, mediante  acciones que te permitan obtener el máximo provecho del mismo.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="info-section" v-if="nSection == 2">
      <div class="container oversized-container">
        <div class="box-info">
          <h3 class="title">Gobierno Corporativo</h3>

          <div class="row">
            <div class="col-lg-3 col-icon">
              <img class="img-icon mw-240" src="public/images/pages/solutions/icon-7.svg">
            </div>

            <div class="col-lg-9 col-text">
              <ol class="list-striped">
                <li>
                  Proveeremos consultoría en liderazgo corporativo para que puedas identificar y extraer el máximo potencial de tu marca, empresa, y colaboradores.
                </li>
                <li>
                  Diseñaremos integralmente tu estrategia de negocio y la evaluaremos continuamente en búsqueda de la mejora continua.
                </li>
                <li>
                  Te asistiremos en la implementación de la normatividad requerida para el adecuado gobierno corporativo de tu organización.
                </li>
                <li>
                  Brindaremos la orientación y guía para la planeación, diseño y ejecución de tu consejo de administración general y comités para tus diferentes áreas.
                </li>
                <li>
                  Seremos tu punto de apoyo en la planeación, dirección, supervisión del logro de objetivos / metas, así como del desempeño de tu empresa.
                </li>
                <li>
                  Emprenderemos en conjunto contigo el proceso EOGE (Estructuración de órganos de gobierno empresariales) que te darán la tranquilidad y seguridad de equilibrio organizacional.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      nSection: 1,
    }
  },

  methods: {
    goToSection(idx) {
      this.nSection = idx;
      document.querySelector('#buttons-section').scrollIntoView({behavior: 'smooth'});
    }
  }
}
</script>
